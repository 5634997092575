import React, { useCallback } from 'react'

import { hooks } from '@front/volcanion'
import { ModelForm } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => props => {
  const { record_id, company_id } = props
  const [user] = hooks.useActiveUser()
  const operator = _.get(user, 'user_id')
  const formProps = {
    name: 'companycontract_form',
    model_name: 'companycontract',
    record_id,
    related_states: [],
    related_searches: [],
    getEmptyForm: useCallback(Callbacks.getEmptyFormHandler(), []),
    recordToForm: useCallback(Callbacks.recordToFormHandler(), []),
    recordToState: useCallback(Callbacks.recordToStateHandler(), []),
    formToRecord: useCallback(Callbacks.formToRecordHandler(company_id, operator, record_id), [company_id, operator, record_id]),
    populate: [
      'companycontract_pricerules.pricerule.pricing',
      'contact',
      'comment',
      'payment_types',
      'source_address'
    ],
    initialState: { isReadOnly: !!record_id, company_id, operator, enableCustomPayment: false },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), [])
  }

  const mergedProps = {
  }

  return (
    <ModelForm {...formProps}>
      <Component {...mergedProps} />
    </ModelForm>
  )
}

export default withForm
