import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'
import { useApplication as useMainApplication } from '@abra/hooks/application'
import Callbacks from './callbacks'
import { HookUtils } from '@front/volcanion/utils'
import { Loader } from '@front/squirtle'


const withContainer = Component => props => {

  const setFormValues = hooks.useFormChange()
  const [defaultCountry, , country_state] = hooks.useSetting('country_code')
  const [isReadOnly] = hooks.useFormState('isReadOnly')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const default_application = _.get(useMainApplication(), 'application_id')
  const [users, searchUser, , { clear: clearUsers }] = hooks.useModelSearch('user', 'get', { populate: ['auth', 'info', 'customerinfo'] })

  const user_id = _.get(_.head(users), 'user_id')
  const setUserInfo = useCallback(Callbacks.setUserInfoHandler(setFormValues), [setFormValues])
  const onFieldClear = useCallback(Callbacks.onFieldClearHandler(setUserInfo, clearUsers), [setUserInfo, clearUsers])
  const onUsersFound = useCallback(Callbacks.onUsersFoundHandler(setUserInfo, user_id, default_application), [setUserInfo, user_id, default_application])
  const searchGSM = useCallback(Callbacks.searchGSMHandler(searchUser, onFieldClear, onUsersFound, default_application), [searchUser, onFieldClear, onUsersFound, default_application])

  const matched_user = _.find(users, ['auth.application', default_application])
  const names = _.join([_.get(matched_user, 'info.last_name'), _.get(matched_user, 'info.first_name')], ' ')
  const mergedProps = {
    names,
    isReadOnly,
    isManagingRecord,
    user_id,
    defaultCountry,
    setUserInfo,
    onFieldClear,
    onUsersFound,
    searchGSM,
  }
  return (
    <Loader isLoading={HookUtils.getLoadingState([country_state])}>
      <Component {...mergedProps} />
    </Loader>
  )
}
export default withContainer
