
class Callbacks {
  static statusChangeHandler(openDialog, setSuspendedState) {
    return async function statusChange(value) {
      if (value === 'suspended')
        openDialog('suspended')
      else
        setSuspendedState({ name: value })
    }
  }
}

export default Callbacks
