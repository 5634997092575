import React, { useCallback } from 'react'
import { hooks } from '@front/volcanion'

const withContainer = Component => props => {

  const mergedProps = {

  }
  return (
    <Component {...mergedProps} />
  )
}
export default withContainer
