import React, { useCallback, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import FormatUtils from '@front/squirtle/utils/format'

import Callbacks from './callbacks'

const withContainer = Component => props => {
  const [user_auth_id] = hooks.useFormState('user_auth_id')
  const [isManagingRecord] = hooks.useFormState('isManagingRecord')
  const [status] = hooks.useFieldValues(['status'])
  const [, { openDialog }] = hooks.useDialogs()
  const suspendedStateName = 'suspended'
  const [, setSuspendedState] = hooks.useFormState(suspendedStateName)
  const verifyClientId = useCallback(hooks.useRecordExist('user', 'info.user_client_id'), [])
  const statusChange = useCallback(Callbacks.statusChangeHandler(openDialog, setSuspendedState), [openDialog, setSuspendedState])

  const statusColor = useMemo(() => FormatUtils.getStatusColor(status), [status])

  const mergedProps = {
    user_auth_id,
    isManagingRecord,
    statusColor,
    statusChange,
    verifyClientId,
  }

  return (
    <Component {...mergedProps} {...props} />
  )
}
export default withContainer
