import React from 'react'
import { I18n } from '@front/volcanion/'

import { Link, Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'
import { TextField, TextPhoneInputField } from '@front/squirtle'

const Title = ({ user_id, names, ...props }) =>
  <Box sx={{ display: 'flex' }}>
    <Typography variant='h5'> {I18n.t('user.info')} </Typography>
    <Link sx={{ ml: 2 }} onClick={() => window.open(`/user/${user_id}`, '_blank')}>
      <Typography sx={{ fontSize: 'h6.fontSize', color: 'primary.main', fontWeight: 400 }}> {names} </Typography>
    </Link>
  </Box>

const Row1 = ({
  isManagingRecord,
  searchGSM,
  user_id,
  defaultCountry,
}) => <Grid container item xs={12} spacing={1}>
    <Grid item xs>
      <TextPhoneInputField
        name={'contact.gsm'}
        label={I18n.t('phone.gsm')}
        afterChange={searchGSM}
        defaultCountry={defaultCountry}
        disabled={!!isManagingRecord}
        debounceTime={250}
        required
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'contact.last_name'}
        label={I18n.t('name.label', { count: 1 })}
        emptyValue='-'
        required={!user_id}
      />
    </Grid>
    <Grid item xs>
      <TextField
        name={'contact.first_name'}
        label={I18n.t('name.first_name', { count: 1 })}
        emptyValue='-'
        required={!user_id}
      />
    </Grid>
  </Grid>


const PrimarySection = ({ names, ...props }) => <>
  <StepLabel> <Title /> </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 3 }}>
      <Grid container rowSpacing={2} >
        <Row1 {...props} />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
