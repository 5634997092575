import FormatUtils from '@front/squirtle/utils/format'

const loadStatus = record => {
  if (record.deleted) return 'inactive'
  else if (record.active) return 'active'
  else return 'inactive'
}

const exportDate = dateValue =>
  !!dateValue ? FormatUtils.FormatDateFrontToBack(dateValue) : undefined

const exportStatus = status => (!!status
  ? {
    active: _.includes(['active'], status),
    deleted: _.includes(['archive'], status)
  }
  : undefined)


const Aux = {
  loadStatus,
  exportDate,
  exportStatus
}

export default Aux
