import { hooks } from '@front/volcanion'

class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        member_status: _.get(record, 'member_status'),
        group_client_id: _.get(record, 'group_client_id'),
        name: _.get(record, 'name'),
        code_language: _.get(record, 'code_language'),
        address: _.get(record, 'address'),
        note: _.get(record, 'note'),
        company_type: _.get(record, 'company_type'),
        family: _.get(record, 'family'),
        registration_number: _.get(record, 'registration_number'),
        tva_intracommunity_number: _.get(record, 'tva_intracommunity_number'),
        contacts: _.get(record, 'contacts'),
        bank_IBAN: _.get(record, 'bank_IBAN'),
        bank_BIC: _.get(record, 'bank_BIC')
      }
    }
  }

  static getEmptyFormHandler() {
    return function getEmptyFormValues() {
      return {
        member_status: true
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      return {
        member_status: _.get(values, 'member_status'),
        group_client_id: _.get(values, 'group_client_id'),
        name: _.get(values, 'name'),
        code_language: _.get(values, 'code_language'),
        address: _.get(values, 'address'),
        note: _.get(values, 'note'),
        company_type: _.get(values, 'company_type'),
        family: _.get(values, 'family'),
        registration_number: _.get(values, 'registration_number'),
        tva_intracommunity_number: _.get(values, 'tva_intracommunity_number'),
        contacts: _.get(values, 'contacts'),
        bank_IBAN: _.get(values, 'bank_IBAN'),
        bank_BIC: _.get(values, 'bank_BIC')
      }
    }
  }

  static recordToStateHandler() {
    return function recordToState(record) {
      return {
        title: _.get(record, 'group_client_id')
      }
    }
  }

  static onSubmitSuccessHandler() {
    return async function onSubmitSuccess(result, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitSuccess ~ result, values, extra, meta", result, values, extra, meta)

    }
  }
  static onSubmitFailedHandler() {
    return async function onSubmitFailed(err, values, extra, meta) {
      console.log("🚀 ~ file: callbacks.js ~ line 27 ~ Callbacks ~ onSubmitFailed ~ err, values, extra, meta", err, values, extra, meta)

    }
  }
}

export default Callbacks
