import React from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, Icon } from '@mui/material'
import { AutocompleteField, TextField, SingleOptionSelectorField, MenuSelectorField } from '@front/squirtle/'

import { isRequired } from '@front/squirtle/utils/validators'

const Title = props =>
  <Grid item xs={12}>
    <Box display='flex' alignItems='center'>
      <Icon color='primary'> {'euro'} </Icon>
      <Typography sx={{ ml: 1, fontSize: 'subtitle.fontSize', color: 'primary.main' }}> {I18n.t('payment.label', { count: 1 })}: </Typography>
    </Box>
  </Grid>

const Row1 = ({ isReadOnly, auth_account, companycontract_id, deferred_billing_options, fill_ref_rule_options, fill_doc_rule_optons, ...props }) =>
  <Grid container item xs={12} spacing={1}>
    <Grid item xs={3}>
      <MenuSelectorField
        label={I18n.t('companyservice.deferred_billing')}
        name={'deferred_billing'}
        options={deferred_billing_options}
        selectFirst
        validate={isRequired}
        required
      />
    </Grid>
    {/* TODO: With the story
      <Grid item xs>
        <TextField
          name={'billing_format'}
          label={I18n.t('ref.billing.format')}
        />
      </Grid> */}
    <Grid item container xs spacing={1}>
      <Grid item xs={3}>
        <SingleOptionSelectorField name={'customerref_option.imposed_ref'} label={I18n.t('ref.billing.imposed')} />
      </Grid>
      <Grid item xs={3}>
        <MenuSelectorField
          name={'customerref_option.fill_ref_rule'}
          label={I18n.t('ref.billing.fill_ref')}
          selectFirst
          required
          options={fill_ref_rule_options}
        />
      </Grid>
      <Grid item xs={3}>
        <MenuSelectorField
          name={'fill_doc_rule'}
          label={I18n.t('companyservice.fill_doc_rule.label')}
          selectFirst
          required
          options={fill_doc_rule_optons}
        />
      </Grid>
      <Grid item xs={3}>
        <SingleOptionSelectorField name={'require_paymentkey'} label={I18n.t('quotation.payment.imposed_key')} />
      </Grid>
    </Grid>

  </Grid >

const Row2 = props =>
  <Grid item xs={12}>
    <AutocompleteField
      name={'customerref_option.refs'}
      label={I18n.t('ref.authorized.list')}
      labelKeys={['client_id']}
      options={[]}
      multiple
      creatable
      createKey={['client_id']}
      autoSelect
    />
  </Grid>

const Payment = props =>
  <Grid container item xs={12} spacing={1} rowSpacing={2}>
    <Title />
    <Row1 {...props} />
    <Row2 {...props} />
  </Grid>

export default React.memo(Payment)
