import React from 'react'
import { I18n } from '@front/volcanion/'

import { Grid, Box, StepLabel, StepContent } from '@mui/material'

import { DateField, TextField, MenuSelectorField, SingleOptionSelectorField } from '@front/squirtle'
import { SuspensionTitleForm } from '@abra/elements/Suspensions'

const Rows = ({ isManagingRecord, statusColor, statusChange, verifyClientId }) =>
  <Grid container spacing={2}>
    <Grid item xs={3}>
      <TextField name='info.user_client_id' label={I18n.t('driver.code')} debounceTime={250} disabled={!!isManagingRecord} afterUserChange={verifyClientId} />
    </Grid>
    <Grid item xs={3}>
      <TextField name='info.last_name' label={I18n.t('name.label', { count: 1 })} required />
    </Grid>
    <Grid item xs={3}>
      <TextField name='info.first_name' label={I18n.t('name.first_name', { count: 1 })} required />
    </Grid>
    <Grid item xs={3} hidden={!isManagingRecord}>
      <TextField name='score.average_rating' label={I18n.t('evaluation.note', { count: 1 })} disabled />
    </Grid>
    <Grid item xs={3} hidden={!isManagingRecord}>
      {!!isManagingRecord && <DateField
        name={'info.createdAt'}
        label={I18n.t('date.create')}
        inputFormat={'DD/MM/YYYY'}
        disabled
        disableOpenPicker
      />}
    </Grid>
    <Grid item xs={3} hidden={!isManagingRecord}>
      {!!isManagingRecord && <DateField
        name={'info.updatedAt'}
        label={I18n.t('date.updatedAt')}
        inputFormat={'DD/MM/YYYY HH:mm'}
        mask={'__/__/____ __:__'}
        disabled
        disableOpenPicker
      />}
    </Grid>
    <Grid item xs={2}>
      <MenuSelectorField
        label={I18n.t('status.label', { count: 1 })}
        name={'status'}
        options={_.compact([
          { label: I18n.t('status.active'), value: 'active' },
          { label: I18n.t('status.deleted'), value: 'deleted', disabled: !isManagingRecord },
          { label: I18n.t('status.suspended'), value: 'suspended', disabled: !isManagingRecord }
        ])}
        valueDecoratorProps={{
          sx: { WebkitTextFillColor: statusColor, fontWeight: 600 }
        }}
        selectFirst
        required
        afterChange={statusChange}
      />
    </Grid>
    <Grid item xs={2}>
      <SingleOptionSelectorField
        name={'driverinfo.can_call_booker'}
        label={I18n.t('call.all_client')}
        clearOnEmpty={false}
      />
    </Grid>
  </Grid>


const PrimarySection = ({ user_auth_id, isManagingRecord, statusColor, statusChange, verifyClientId }) => (
  <>
    <StepLabel>
      <SuspensionTitleForm
        label={I18n.t('info.general')}
        record_id={user_auth_id}
      />
    </StepLabel>
    <StepContent>
      <Box sx={{ p: 2, boxShadow: 6, borderRadius: 1 }}>
        <Rows isManagingRecord={isManagingRecord} statusColor={statusColor} statusChange={statusChange} verifyClientId={verifyClientId} />
      </Box>
    </StepContent>
  </>
)
export default React.memo(PrimarySection)
