
import React from 'react'
import { I18n } from '@front/volcanion'

import { Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import Dialogs from './Popup'
import {
  AdherentsSection,
  ConnectionSection,
  CustomerBlacklistSection,
  HeatmapBlacklist,
  HistorySection,
  OptionsSection,
  PrimarySection,
  SecondarySection,
  ServiceBlacklistSection
} from './Section'

import LoginSection from '@abra/frames/LoginSection'
import FormHeader, { Title } from '@abra/elements/headers/Form'
import FormSpeedDial from '@abra/elements/speedDial/Form'
import GroupTab from '@abra/panels/Tab/Group'

import TitleComponent from './Title'

const Driver = ({ isManagingRecord, record_id, group_id, onResetSmsPassword, onResetEmailPassword, hasPassword, hasGsm, handleCall, onCallDriver, ...rest }) => {

  const actions = [
    { name: I18n.t('action.reset_password'), iconName: 'sms', onClick: onResetSmsPassword, hidden: !record_id || !hasPassword },
    { name: I18n.t('action.reset_password'), iconName: 'alternate_email', onClick: onResetEmailPassword, hidden: !record_id || !hasPassword },
    { name: I18n.t('action.call_driver'), iconName: 'phone', onClick: onCallDriver, hidden: !record_id || !hasGsm },
  ]

  return (
    <>
      <Dialogs handleCall={handleCall} />
      <Grid container justifyContent='center'>
        <Grid item xs={10}>
          <Grid container rowSpacing={4}>
            <Grid item xs={12} hidden={!group_id}> <GroupTab value={4} /> </Grid>
            <Grid item xs={12} >
              <Grid container >
                <Grid item xs>
                  <FormHeader labelKey={'driver'}>
                    <Title><TitleComponent  {...rest} /></Title>
                  </FormHeader>
                </Grid>
                <Grid item xs={12} >
                  <GridStepper groupsCount={[9]} activeStep={0} orientation={'vertical'}>
                    <Step><PrimarySection /></Step>
                    <Step display='flex'>
                      <LoginSection />
                      <ConnectionSection />
                    </Step>
                    <Step><SecondarySection /></Step>
                    <Step><OptionsSection /></Step>
                    <Step><HeatmapBlacklist /></Step>
                    <Step>{!!isManagingRecord && <CustomerBlacklistSection />}</Step>
                    <Step>{!!isManagingRecord && <ServiceBlacklistSection />}</Step>
                    <Step><AdherentsSection /></Step>
                    <Step>{!!isManagingRecord && <HistorySection />}</Step>
                  </GridStepper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <FormSpeedDial actions={actions} modifyAllowed={!!isManagingRecord} />
    </>
  )
}

export default React.memo(Driver)
