
import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Step } from '@mui/material'
import { GridStepper } from '@front/squirtle'

import FormHeader from '@abra/elements/headers/Form'

import PrimarySection from './Section/Primary'
import SettingSection from './Section/Setting'
import SchedulesSection from './Section/Schedules'
import DriverBlacklistSection from './Section/DriverBlacklist'

const Companyservice = ({ companyContractLabel, companycontract_id, company, ...props }) => <>
  <Box className={'safearea'}>
    <Grid container justifyContent='center' alignItems='center'>
      <Grid container item xs={12} sm={10} xl={8} rowSpacing={1}>
        <Grid item xs={12} >
          <FormHeader
            subTitle={I18n.t('link.companyservice')}
            onLinkClick={() => window.open(`/company/${company}/contract/${companycontract_id}`, '_blank')}
            linkText={companyContractLabel}
            closeOnBack={!!companycontract_id}
          />
        </Grid>
        <Grid item xs={12} >
          <GridStepper groupsCount={[4]} activeStep={0} orientation="vertical">
            <Step>
              <PrimarySection />
            </Step>
            <Step>
              <SettingSection />
            </Step>
            <Step>
              <DriverBlacklistSection />
            </Step>
            <Step>
              <SchedulesSection />
            </Step>
          </GridStepper>
        </Grid>
      </Grid>
    </Grid>
  </Box>
</>

export default React.memo(Companyservice)
